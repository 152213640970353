<template>
    <div class="testimonial-container">
        <div class="stats">
            <a href="https://www.google.com/search?q=coastal+luxe+living&rlz=1C1CHBF_enUS791US791&oq=coastal+luxe+living&aqs=chrome..69i57j69i60l3j0.4985j1j7&sourceid=chrome&ie=UTF-8#lrd=0x80c2ba86636cd907:0xba7c6e569daa8bae,3" target="_blank" rel="noopener noreferrer">
                <h2>
                    46
                </h2>
                <h3>
                    Five Star Reviews
                </h3>
            </a>
            <div class="stars">
                <img src="/images/star.svg" alt="" srcset="">
                <img src="/images/star.svg" alt="" srcset="">
                <img src="/images/star.svg" alt="" srcset="">
                <img src="/images/star.svg" alt="" srcset="">
                <img src="/images/star.svg" alt="" srcset="">
            </div>
        </div>
        <vueper-slides 
            :bullets="false"
            :arrows="true"
            class="no-shadow testimonial-slideshow"
            :touchable="true"
            :pauseOnHover="false"
            :fixedHeight="true"
            :speed="7000"
            :parallax="false">
            <vueper-slide
                v-for="(slide, i) in data"
                :key="i"
                class="slide">
                <template v-slot:content>
                    <p>
                        {{slide.Content}}
                    </p>
                    <h3 v-if="slide.Author">
                       - {{slide.Author}}
                    </h3>
                </template>
            </vueper-slide>
        </vueper-slides>
    </div>
</template>

<script>
import { VueperSlides, VueperSlide } from 'vueperslides'

export default {
    name: "Testimonial",
    components: { VueperSlides, VueperSlide },
    props: ['data'],
}
</script>

<style lang="scss" scoped>
.testimonial-container{
    padding: 0 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .stats{
        position: relative;
        color: #fff;
        text-align: center;
        margin-top: 0;
        padding-top: 30vh;
        a{
            font-family: sweet-sans-pro,sans-serif;
            color: #fff;
            h2{
                margin-top: 0;
                font-size: 50px;
                font-size: 80px;
                &:hover{
                    opacity: 0.75;
                }
            }
            h3{
                margin: 0;
                margin-top: 1rem;
                font-size: 50px;
                &:hover{
                    opacity: 0.75;
                }
            }
        }
        img{
            width: 25px;
            height: auto;
        }
        .stars{
            margin-top: 3rem;
        }
    }
}
@media (max-width: $mobile) {
    .testimonial-container{
        padding: 0 0.75rem;
        background: #1a1a1a;
        .stats{
            padding-top: 35vh;
            .stars{
                margin-top: 0.5rem;
            }
        }
    }
    
}
</style>
<style lang="scss">
.testimonial-container{
    .slide, .vueperslide--clone{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        h2{
        font-weight: 600;
            text-align: center;
            font-size: 13px;
            letter-spacing: 3px;
            text-transform: uppercase;
            color: #fff;
        }
        p{
            margin: 0 20%;
            color: #fff;
        }
        h3{
            font-weight: 100;
            letter-spacing: 2px;
            color: #fff;
        }
    }
    .vueperslides--fixed-height { height: 150px; }
    .vueperslides__arrow{
        padding-bottom: 30vh;
    }
}
@media (max-width: $mobile) { 
    .testimonial-container{
        .vueperslides--fixed-height { height: 40vh; }
        .slide, .vueperslide--clone{
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            text-align: center;
            p{
                margin: 1rem 3rem;
                color: #fff;
            }
            h3{
                font-weight: 100;
                letter-spacing: 2px;
                color: #fff;
            }
        }
        .vueperslides__arrow--prev{
            left: -0.5rem;
        }
        .vueperslides__arrow--next{
            right: -0.5rem;
        }
    }
}
</style>